//eslint-disable-next-line import/no-cycle
import { CustomerEntity } from './customer.entity';
import { CustomerDeviceEntity } from './customer-device.entity';

export type ExternalCustomerEntity = {
  readonly id: string;
  readonly externalId: string;
  readonly internalId: string;
  readonly partnerId: string;
  isProcPersonalData: boolean | null;
  dateProcPersonalData: Date | null;
  customer?: CustomerEntity;
  devices?: CustomerDeviceEntity[];
  readonly createdAt: Date;
  updatedAt: Date;
};

export type ExternalCustomerEntityRelationKey = keyof Pick<ExternalCustomerEntity, 'devices'>;
export type ExternalCustomerEntityRelations = ExternalCustomerEntityRelationKey[] | 'all';
