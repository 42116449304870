export type DeviceQueuedUpgradeEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Идентификатор */
  readonly deviceId: string;
  /** Идентификатор */
  readonly serial: string;
  /** Идентификатор */
  readonly createdAt: Date;
};
