import { Actor } from '@store/amqp-contracts';

import { CustomerEntity, DeviceEntity, VehicleEntity } from '../control-center';
import { TicketStatus, TicketType } from './enums';
import { TicketCommentEntity } from './ticket-comment.entity';

export type DeletePersonalDataTicketPayload = {
  //...
};

export type AddVehicleTicketPayload = {
  customer: Pick<CustomerEntity, 'id' | 'firstName' | 'lastName' | 'email' | 'phone'>;
  vehicle: Pick<VehicleEntity, 'vin'> & { sts: string; license: string | null };
  ownershipStartDate: Date;
  images: string[];
  type: TicketType;
  reviewComment?: string;
  hasOwnership?: boolean;
};

export enum EInstallDeviceTicketType {
  Pro = 'PRO',
  Lite = 'LITE',
  Upgrade = 'UPGRADE',
}

export type InstallDeviceTicketPayload = {
  vehicle:
    | (Pick<VehicleEntity, 'vin' | 'brand' | 'model'> & { yearOfProduction: number; partnerId: string | null })
    | null;
  currentDevice: Pick<DeviceEntity, 'deviceId' | 'serial' | 'model' | 'type'> | null;
  device: Pick<DeviceEntity, 'model' | 'type' | 'serial'>;
  installationType: EInstallDeviceTicketType;
  dealer: {
    sapCode: string | null;
    dealerName: string;
    dealerComment: string;
  };
  contactPhone: string;
  images: string[];
  ticketId: string;
  reviewComment?: string;
  type: TicketType;
  isInstalled?: boolean;
};

export type TicketPayload = DeletePersonalDataTicketPayload | AddVehicleTicketPayload | InstallDeviceTicketPayload;

export type TicketEntity = {
  readonly id: string;
  partnerId: string | null;
  readonly type: TicketType;
  readonly actor: Actor;
  active: boolean;
  status: TicketStatus;
  statusUpdatedDate: Date | null;
  payload: TicketPayload;
  readonly createdAt: Date;
  updatedAt: Date;

  comments?: TicketCommentEntity[];
};

export type TicketEntityRelationKey = keyof Pick<TicketEntity, 'comments'>;
export type TicketEntityRelations = TicketEntityRelationKey[] | 'all';
