/* eslint-disable import/no-cycle */
import { TariffEntity } from '../../subscriptions';
import { OwnershipEntity } from '../ownerships';
import { SubscriptionPaymentEntity } from './subscription-payment.entity';

export enum InvoiceStatus {
  New = 'new',
  Pending = 'pending',
  Paid = 'paid',
  Expired = 'expired',
  Error = 'error',
  Reversed = 'reversed',
}

/** Начисление */
export type InvoiceEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Уникальный номер постановления */
  readonly invoiceNumber: string;
  /** Идентификатор клиента */
  readonly customerId: string;
  /** Идентификатор владения */
  readonly ownershipId: string;
  /** Дата истечения срока оплаты */
  readonly expirationDate: Date;
  /** Описание */
  readonly description: string;
  /** Сумма (копейки) */
  readonly amount: number;
  /** Идентификатор тарифа */
  tariffId: string;
  /** Статус оплаты */
  status: InvoiceStatus;
  /** Идентификатор успешной оплаты */
  successPaymentId: string | null;
  /** Дата фактической оплаты */
  paidAt: Date | null;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
  /** Сущности платежей по начислению */
  payments?: SubscriptionPaymentEntity[];
  /** Сущность тарифа */
  tariff?: TariffEntity;
  /** Сущность владения */
  ownership?: OwnershipEntity;
};

export type InvoiceEntityRelationKey = keyof Pick<InvoiceEntity, 'payments' | 'tariff' | 'ownership'>;
export type InvoiceEntityRelations = InvoiceEntityRelationKey[] | 'all';

export const INVOICE_STATUS_TRANSITIONS_MAP = new Map<InvoiceStatus, InvoiceStatus[]>([
  [InvoiceStatus.New, [InvoiceStatus.Pending, InvoiceStatus.Error, InvoiceStatus.Expired, InvoiceStatus.Paid]],
  [InvoiceStatus.Pending, [InvoiceStatus.Paid, InvoiceStatus.Expired]],
  [InvoiceStatus.Paid, []],
  [InvoiceStatus.Expired, []],
  [InvoiceStatus.Error, [InvoiceStatus.Pending, InvoiceStatus.Paid]],
  [InvoiceStatus.Reversed, []],
]);
