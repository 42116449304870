import { DeviceType } from '../../control-center';
import { Project } from '../../project.enum';
import { NotificationCode } from '../notification.entity';

export type TestPush = {
  code: NotificationCode.Test;
  payload: Record<string, never>;
};

export type SuccessSubscriptionActivationPush = {
  code: NotificationCode.SuccessSubscriptionActivation;
  payload: {
    vin: string;
    deviceType: DeviceType;
    project: Project;
    subscriptionId: string;
  };
};

export type FirstAttemptFailedSubscriptionActivationPush = {
  code: NotificationCode.FirstAttemptFailedSubscriptionActivation;
  payload: {
    vin: string;
  };
};

export type LastAttemptFailedSubscriptionActivationPush = {
  code: NotificationCode.LastAttemptFailedSubscriptionActivation;
  payload: {
    vin: string;
  };
};

export type GiftedSubscriptionExpiringPush = {
  code: NotificationCode.GiftedSubscriptionExpiring;
  payload: {
    vin: string;
    daysLeft: number;
  };
};

export type SubscriptionExpiringPush = {
  code: NotificationCode.SubscriptionExpiring;
  payload: {
    vin: string;
    daysLeft: number;
  };
};

export type SubscriptionClosedPush = {
  code: NotificationCode.SubscriptionClosed;
  payload: {
    vin: string;
    brand: string;
    model: string;
    project: Project;
  };
};

export type SubscriptionPaymentFailedPush = {
  code: NotificationCode.SubscriptionPaymentFailed;
  payload: {
    vin: string;
    deviceType: DeviceType;
    brand: string;
    model: string;
  };
};

export type FineNewPush = {
  code: NotificationCode.FineNew;
  payload: {
    vin: string;
    daysFromCreated: number;
  };
};

export type FineNewWithDiscountPush = {
  code: NotificationCode.FineNewWithDiscount;
  payload: {
    vin: string;
    daysFromCreated: number;
  };
};

export type FineNewWithExpiringDiscountPush = {
  code: NotificationCode.FineNewWithDiscountExpiring;
  payload: {
    vin: string;
    daysToExpiringDiscount: number;
  };
};

export type CkadNewPush = {
  code: NotificationCode.CkadNew;
  payload: {
    vin: string;
    daysFromCreated: number;
  };
};

export type CkadNewWithoutPaymentPush = {
  code: NotificationCode.CkadNewWithoutPayment;
  payload: {
    vin: string;
    daysToTravelDateExpired: number;
  };
};

export type DealerCrmDistributionPush = {
  code: NotificationCode.DealerCrmDistribution;
  payload: {
    title: string;
    message: string;
    vin: string;
  };
};

export type MaintenanceConfirmedPush = {
  code: NotificationCode.MaintenanceConfirmed;
  payload: {
    vin: string;
    dateTime: string;
    dealerName: string;
  };
};

export type MaintenanceRejectedPush = {
  code: NotificationCode.MaintenanceRejected;
  payload: {
    vin: string;
  };
};

export type MaintenanceBeforeReminderPush = {
  code: NotificationCode.MaintenanceBeforeReminder;
  payload: {
    vin: string;
    dateTime: string;
    dealerName: string;
  };
};

export type MaintenanceAfterRatePush = {
  code: NotificationCode.MaintenanceAfterRate;
  payload: {
    vin: string;
  };
};

export type DynamicPush = {
  code: string;
  payload: Record<string, string | number | boolean>;
};

export type VehicleTowingPush = {
  code: NotificationCode.VehicleTowing;
  payload: {
    vin: string;
    brand: string;
    model: string;
  };
};

export type VehicleRoadAccidentPush = {
  code: NotificationCode.VehicleRoadAccident;
  payload: {
    vin: string;
    brand: string;
    model: string;
  };
};

export type TicketDone = {
  code: NotificationCode.TicketDone;
  payload: {};
};

export type TicketReturned = {
  code: NotificationCode.TicketReturned;
  payload: {};
};

export type TicketRejected = {
  code: NotificationCode.TicketRejected;
  payload: {};
};

export type PushTemplateType =
  | TestPush
  | SuccessSubscriptionActivationPush
  | FirstAttemptFailedSubscriptionActivationPush
  | LastAttemptFailedSubscriptionActivationPush
  | GiftedSubscriptionExpiringPush
  | SubscriptionExpiringPush
  | SubscriptionClosedPush
  | SubscriptionPaymentFailedPush
  | FineNewPush
  | FineNewWithDiscountPush
  | FineNewWithExpiringDiscountPush
  | CkadNewPush
  | CkadNewWithoutPaymentPush
  | DealerCrmDistributionPush
  | MaintenanceConfirmedPush
  | MaintenanceRejectedPush
  | MaintenanceBeforeReminderPush
  | MaintenanceAfterRatePush
  | DynamicPush
  | VehicleTowingPush
  | VehicleRoadAccidentPush
  | TicketReturned
  | TicketDone
  | TicketRejected;

export type PushType =
  | {
      byTemplate: false;
      title: string;
      message: string;
    }
  | {
      byTemplate: true;
      push: PushTemplateType;
      partnerId: string | null;
    };
