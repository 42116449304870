export enum ECommandType {
  BlockEngine = 'OUTON', // Отменить блокировку двигателя
  UnblockEngine = 'OUTOFF', // Заблокировать двигатель
  OpenDoors = 'GRDOFF', // Открыть двери
  CloseDoors = 'GRDON', // Закрыть двери
  SetGuard = 'SETGUARD', // Включить режим охраны
  ClearGuard = 'CLRGUARD', // Отключить режим охраны
  StartEngine = 'ENGSTART', // Запуск двигателя
  StopEngine = 'ENGSTOP', // Остановка двигателя в режиме автозапуска
  Avar = 'AVAR', //  единая команда управления “аварийкой”
  AvarOpen = 'AVAROPEN', // используется при открытии автомобиля
  AvarClose = 'AVARCLOSE', // используется при закрытии автомобиля
  CloseWindows = 'OKNA', // Закрытие окон
  OpenBag = 'OPENBAG', // Открытие багажника
  Beep = 'BEEP', // Гудок
  IgnitionOn = 'IGN1ON', // Включение зажигания
  IgnitionOff = 'IGN1OFF', // Выключение зажигания
}

export enum EControlCommandType {
  // Заблокировать двигатель
  BlockEngine = 'OUTON',
  // Отменить блокировку двигателя
  UnblockEngine = 'OUTOFF',
  // Экстренное включение блокировки
  BlockEngineExtr = 'OUTONEXTR',
  // Открыть двери
  OpenDoors = 'GRDOFF',
  // Закрыть двери
  CloseDoors = 'GRDON',
  // Включить режим охраны
  SetGuard = 'SETGUARD',
  // Отключить режим охраны
  ClearGuard = 'CLRGUARD',
  // Запуск двигателя
  StartEngine = 'ENGSTART',
  // Остановка двигателя в режиме автозапуска
  StopEngine = 'ENGSTOP',
  //  единая команда управления “аварийкой”
  Avar = 'AVAR',
  // используется при открытии автомобиля
  AvarOpen = 'AVAROPEN',
  // используется при закрытии автомобиля
  AvarClose = 'AVARCLOSE',
  // Закрытие окон
  CloseWindows = 'OKNA',
  // Открытие багажника
  OpenBag = 'OPENBAG',
  // Гудок
  Beep = 'BEEP',
  // Начать сеанс определения стиля вождения
  StartStyle = 'STLSTART',
  // Завершить сеанс определения стиля вождения и выдать окончательный результат в %
  StopStyle = 'STLSTOP',
  /**
   * Включает зажигание в автомобиле
   * Через некоторое время (0.5 - 1 - 2 минуты) с автомобиля должно прийти обновленное значение уровня топлива
   * Автоматическое отключение происходит через 3 минуты
   */
  RecalculateFuel = 'IGN1ON',
  /**
   * Включает зажигание в автомобиле
   */
  IgnitionOff = 'IGN1OFF',
  /**
   * Функция управление климатом
   * 1 - вкл/ выкл климата (0-выкл, 1-вкл)
   * 2 - забор воздуха (0-рециркуляция, 1-с улицы)
   * 3 - режим auto (0-выкл, 1-вкл)
   * 4 - обогрев лобового стекла (0-выкл, 1-вкл)
   * 5 - обогрев заднего стекла (0-выкл, 1-вкл)
   * 6 - ионизация (0-выкл, 1-вкл)
   * 7 - управление скоростью вентилятора (0-не исп., 1-мин, 8-макс)
   * 8 - управление кондиционером (0-выкл, 1-вкл)
   * 9 - изменение заслонок (0-в лицо, 1-в лицо и ноги, 2-в ноги, 3-в ноги и на стекло)
   * 10 - изменение температуры (0-16 градусов ... 14-32 градуса, 15-HI)
   * 11 - обдув стекла (0-выкл, 1-вкл)
   * 12 - Дефростер (0-выкл, 1-вкл)
   * 13 - Подогрев сидений (0-выкл, 1-вкл)
   * 14 - Обдув сидений (0-выкл, 1-вкл)
   */
  Climate = 'CLM',
}

export enum ESettingsCommandType {
  // Получение статуса устройства
  Activation = 'actv',
  // "s" Получение статуса устройства, ответ аналогичен "actv"
  // Зачем она существует? ¯\_(ツ)_/¯
  Status = 'status',
  // Получение статуса устройства
  AccelerometerMovement = 'sdrv',
  // Получение статуса устройства
  AutostartTimer = 'et',
  // Установка/чтение пробега, ответ опять же аналогичен ответ аналогичен "actv"
  Mileage = 'run',
  // Настройка сервера
  Server = 'gpr',
  // Установка/чтение модели автомобиля
  VehicleModel = 'hmod',
}

/**
 * Не требуют пароля
 */
export enum EBinaryCommandType {
  // "{A.." Получение информации по устройству (sim1, sim2, imei, серийник)
  GetInfo = 'GETINFO',
  // "{A,," Получение статуса устройства, ответ аналогичен "actv", не считая первого байта данных ("actv" начинается с пробела, "{A,," с {
  // Ответ вернется
  GetStatus = 'GETSTATUS',
  // Остановка выполнения текущей команды, в отве приходит пакет телематики, начинающийся на 0xF4/"O"/79, формат аналогичен 81/0x51/'Q'
  StopPrevious = 'STOPPREVIOUS',
}

export enum EUnknownCommandType {
  Unknown = 'Unknown',
}

export type TCommandType = EControlCommandType | ESettingsCommandType | EBinaryCommandType | EUnknownCommandType;

export enum EClimateFunction {
  // 1 - вкл/ выкл климата (0-выкл, 1-вкл)
  ClimateOnOff = 1,
  // 2 - забор воздуха (0-рециркуляция, 1-с улицы)
  AirIntake = 2,
  // 3 - режим auto (0-выкл, 1-вкл)
  AutoMode = 3,
  // 4 - обогрев лобового стекла (0-выкл, 1-вкл)
  FrontWindowHeating = 4,
  // 5 - обогрев заднего стекла (0-выкл, 1-вкл)
  RearWindowHeating = 5,
  // 6 - ионизация (0-выкл, 1-вкл)
  Ionization = 6,
  // 7 - управление скоростью вентилятора (0-не исп., 1-мин, 8-макс)
  FanSpeedControl = 7,
  // 8 - управление кондиционером (0-выкл, 1-вкл)
  AirConditioningControl = 8,
  // 9 - изменение заслонок (0-в лицо, 1-в лицо и ноги, 2-в ноги, 3-в ноги и на стекло)
  VentilationDirection = 9,
  // 10 - изменение температуры (0-16 градусов ... 14-32 градуса, 15-HI)
  TemperatureControl = 10,
  // 11 - обдув стекла (0-выкл, 1-вкл)
  WindshieldBlow = 11,
  // 12 - Дефростер (0-выкл, 1-вкл)
  Defroster = 12,
  // 13 - Подогрев сидений водителя (0-выкл, 1-вкл)
  SeatHeating = 13,
  // 14 - Обдув сидений (0-выкл, 1-вкл)
  SeatBlow = 14,
  // Подогрев сиденья пассажира 0/ 1 (0-выкл, 1-вкл)
  FrontPassengerSeatHeating = 15,
  // Подогрев сиденья пассажира 0/ 1 (0-выкл, 1-вкл)
  RearLeftPassengerSeatHeating = 16,
  // Подогрев сиденья пассажира 0/ 1 (0-выкл, 1-вкл)
  RearRightPassengerSeatHeating = 17,
}
