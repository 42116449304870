import { ControlCenterAutostartTimerOutboxEntities } from './autostart-timer';
import { ControlCenterCityOutboxEntities } from './city';
import { ControlCenterCustomerOutboxEntities } from './customer';
import { ControlCenterDeviceOutboxEntities } from './device';
import { ControlCenterInvoiceOutboxEntities } from './invoice';
import { ControlCenterOfferOutboxEntities } from './offer';
import { ControlCenterOwnershipOutboxEntities } from './ownership';
import { ControlCenterPartnerOutboxEntities } from './partner';
import { ControlCenterSimOutboxEntities } from './sim';
import { ControlCenterSubscriptionOutboxEntities } from './subscription';
import { ControlCenterSubscriptionPaymentOutboxEntities } from './subscription-payment';
import { ControlCenterTariffOutboxEntities } from './tariff';
import { ControlCenterVehicleOutboxEntities } from './vehicle';

export type ControlCenterOutboxEntities =
  | ControlCenterCustomerOutboxEntities
  | ControlCenterDeviceOutboxEntities
  | ControlCenterInvoiceOutboxEntities
  | ControlCenterOfferOutboxEntities
  | ControlCenterOwnershipOutboxEntities
  | ControlCenterPartnerOutboxEntities
  | ControlCenterSimOutboxEntities
  | ControlCenterSubscriptionOutboxEntities
  | ControlCenterSubscriptionPaymentOutboxEntities
  | ControlCenterTariffOutboxEntities
  | ControlCenterAutostartTimerOutboxEntities
  | ControlCenterCityOutboxEntities
  | ControlCenterVehicleOutboxEntities;

export type ControlCenterOutboxType = ControlCenterOutboxEntities['type'];

export * from './customer';
export * from './device';
export * from './invoice';
export * from './offer';
export * from './ownership';
export * from './partner';
export * from './sim';
export * from './subscription';
export * from './subscription-payment';
export * from './tariff';
export * from './vehicle';
export * from './autostart-timer';
export * from './city';
