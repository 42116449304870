import { Currency, ExtraParameters } from '../payment';
import { FacilityEntity } from './facility.entity';

import { FacilityOrderData } from './interfaces';

export enum FacilityOrderStatus {
  WaitForPayment = 'WAIT_FOR_PAYMENT',
  WaitUntilComplete = 'WAIT_UNTIL_COMPLETE',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
}

/** Заказ услуги */
export type FacilityOrderEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Идентификатор клиента */
  readonly customerId: string;
  /** Идентификатор владения */
  readonly ownershipId: string | null;
  /** Идентификатор услуги */
  readonly facilityId: string;
  /** Стоимость */
  readonly price: number;
  /** Комиссия */
  readonly fee: number;
  /** Валюта */
  readonly currency: Currency;
  /** Статус заказа */
  status: FacilityOrderStatus;
  /** Дополнительные данные по заказу */
  data: FacilityOrderData;
  /** Экстра параметры */
  extraParameters: ExtraParameters | null;
  /** Отзыв */
  review: string | null;
  /** Оценка */
  rate: number | null;
  callbackUrl: string | null;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;

  /** Сущность услуги */
  facility?: FacilityEntity;
};

export type FacilityOrderEntityRelationKey = keyof Pick<FacilityOrderEntity, 'facility'> | 'ownership';
export type FacilityOrderEntityRelations = FacilityOrderEntityRelationKey[] | 'all';

export const FACILITY_ORDER_STATUS_MAP = new Map<FacilityOrderStatus, FacilityOrderStatus[]>([
  [FacilityOrderStatus.WaitForPayment, [FacilityOrderStatus.WaitUntilComplete, FacilityOrderStatus.Closed]],
  [FacilityOrderStatus.WaitUntilComplete, [FacilityOrderStatus.Completed, FacilityOrderStatus.Canceled]],
  [FacilityOrderStatus.Completed, []],
  [FacilityOrderStatus.Canceled, []],
  [FacilityOrderStatus.Closed, []],
]);
