export type DeviceTestVehicleModelEntity = {
  /** Код комплектации */
  equipmentCode: string;

  /** Код модели авто для блока Misos */
  modelCode: number;

  /** Дата создания */
  readonly createdAt: Date;

  /** Дата обновления */
  updatedAt: Date;
};
