export interface DeviceTestLastPacket {
  /** Широта */
  latitude: number;
  /** Долгота */
  longitude: number;
  /** Высота, м */
  height: number;
  /**
   * Значение в условных единицах 1 у.е. = Градусы/180 * 128, 0 = восток, против часовой стрелки
   * Например значение 64 = 90 градусов против часовой, т.е. север
   */
  angle: number;
  /** Скорость, км/ч */
  speed: number;
  /** Дата создания пакета данных */
  packetTime: Date;
  /** Дата получения пакета данных сервером */
  packetReceiveTime: Date;
  /** Процент топлива, от 0 до 100  */
  fuelPercent: number;
  /** Количество топлива, л */
  fuelLiters: number;
  /** Пробег, км */
  mileage: number;
  /** Зажигание */
  ignition: boolean;
  /** Стиль вождения */
  drivingStyle: number;
  /** Подключено ли устройство к серверу */
  connected: boolean;
  /** Напряжение аккумулятора, В */
  batteryVoltage: number | null;
  /** @deprecated Открыта ли любая дверь */
  isDoorsOpened: boolean;
  /** Открыт ли Центральный Замок */
  isCentralLockOpen: boolean;
  /** Открыта ли водительская дверь */
  isDriverDoorOpen: boolean;
  /** Открыта ли передняя пассажирская дверь */
  isFrontPassengerDoorOpen: boolean;
  /** Открыта ли одна из задних пассажирских дверей */
  isAnyRearDoorOpen: boolean;
  /** Включены ли фары ближнего света */
  areLowBeamHeadlightsOn: boolean;
  /** Включены ли фары дальнего света */
  areHighBeamHeadlightsOn: boolean;
  /** Включены ли ходовые огни */
  areParkingLightsOn: boolean;
  /** Открыт ли капот автомобиля */
  isHoodOpened: boolean;
  /** Открыт ли багажник */
  isTrunkOpened: boolean;
  /** Моточасы, ч */
  motoHours: number;
  /** Заблокирован ли двигатель */
  engineBlock: boolean;
  /** Статус первой КАН-шины */
  can1Status: boolean;
  /** Статус второй КАН-шины */
  can2Status: boolean;
}
