export type StoryViewEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Идентификатор истории */
  readonly storyId: string;
  /** Идентификатор клиента */
  readonly customerId: string;
  /** Просмотрена ли история */
  viewed: boolean;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
};

export type StoryViewEntityRelations = [] | 'all';
