import { FinePaidStatus } from './interfaces';

export type CkadEntity = {
  readonly id: string;
  readonly vin: string;
  readonly travelId: string;
  amount: number;
  dateTravel: Date;
  route: string;
  resolutionNumber: string | null;
  paidStatus: FinePaidStatus;
  facilityOrderId: string | null;
  paidAt: Date | null;
  readonly createdAt: Date;
  updatedAt: Date;
};
