import { WidgetDataCkads } from './widget-data-ckads.interface';
import { WidgetDataDealer } from './widget-data-dealer.interface';
import { WidgetDataDrivers } from './widget-data-drivers.interface';
import { WidgetDataFines } from './widget-data-fines.interface';
import { WidgetDataMaintenance } from './widget-data-maintenance.interface';
import { WidgetDataMonitoring } from './widget-data-monitoring.interface';
import { WidgetDataStatistics } from './widget-data-statistics.interface';
import { WidgetDataSubscription } from './widget-data-subscription.interface';
import { WidgetDataVehicleInfo } from './widget-data-vehicle-info.interface';

export type WidgetData =
  | WidgetDataMonitoring
  | WidgetDataStatistics
  | WidgetDataDrivers
  | WidgetDataDealer
  | WidgetDataMaintenance
  | WidgetDataSubscription
  | WidgetDataFines
  | WidgetDataCkads
  | WidgetDataVehicleInfo;

export * from './widget-data-monitoring.interface';
export * from './widget-data-statistics.interface';
export * from './widget-data-drivers.interface';
export * from './widget-data-dealer.interface';
export * from './widget-data-maintenance.interface';
export * from './widget-data-subscription.interface';
export * from './widget-data-ckads.interface';
export * from './widget-data-fines.interface';
export * from './widget-data-vehicle-info.interface';
