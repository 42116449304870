import { ControlCenterOwnershipCreatedOutboxEntity } from './control-center-ownership-created-outbox.entity';
import { ControlCenterOwnershipDriverAddedOutboxEntity } from './control-center-ownership-driver-added-outbox.entity';
import { ControlCenterOwnershipDriverDeletedOutboxEntity } from './control-center-ownership-driver-deleted-outbox.entity';
import { ControlCenterOwnershipFinishedOutboxEntity } from './control-center-ownership-finished-outbox.entity';
import { ControlCenterOwnershipUpdatedOutboxEntity } from './control-center-ownership-updated-outbox.entity';

export type ControlCenterOwnershipOutboxEntities =
  | ControlCenterOwnershipCreatedOutboxEntity
  | ControlCenterOwnershipUpdatedOutboxEntity
  | ControlCenterOwnershipDriverAddedOutboxEntity
  | ControlCenterOwnershipDriverDeletedOutboxEntity
  | ControlCenterOwnershipFinishedOutboxEntity;

export * from './control-center-ownership-outbox-event-type.enum';
export * from './control-center-ownership-created-outbox.entity';
export * from './control-center-ownership-updated-outbox.entity';
export * from './control-center-ownership-driver-added-outbox.entity';
export * from './control-center-ownership-finished-outbox.entity';
export * from './control-center-ownership-driver-deleted-outbox.entity';
