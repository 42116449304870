import { AttachmentLike } from 'nodemailer/lib/mailer';
import { Readable } from 'stream';

export enum EmailTemplateEnum {
  RequestPersonalData = 'request_delete_personal_data',
}

export class EmailEntity {
  id?: number;

  status!: number;

  email!: unknown;

  sendResult?: object;
}

/**
 * Интерфейс вложения
 */
export interface IMailAttachment {
  /** Имя файла */
  filename?: string;

  /** Путь до файла */
  path?: string;

  /** Контентная часть */
  raw?: string | Buffer | Readable | AttachmentLike;

  /** Истоник потока */
  streamSource?: unknown;

  /** Заголовки сообщения */
  headers?: IMailAttachmentType;
}

/**
 * Тип вложения
 */
export type IMailAttachmentType =
  | { [key: string]: string | string[] | { prepared: boolean; value: string } }
  | Array<{ key: string; value: string }>;
