export * from './brand-permissions.config';
export * from './catalog-record-permissions.config';
export * from './ckad-permissions.config';
export * from './color-permissions.config';
export * from './configuration-permissions.config';
export * from './customer-permissions.config';
export * from './device-permissions.config';
export * from './equipment-permissions.config';
export * from './events-history-permissions.config';
export * from './facility-permissions.config';
export * from './fine-permissions.config';
export * from './generation-color-permissions.config';
export * from './generation-permissions.config';
export * from './invoice-permissions.config';
export * from './model-permissions.config';
export * from './ownership-permissions.config';
export * from './partner-permissions.config';
export * from './provider-permissions.config';
export * from './sim-card-permissions.config';
export * from './story-permissions.config';
export * from './subscription-permissions.config';
export * from './tariff-permissions.config';
export * from './ticket-permissions.config';
export * from './user-permissions.config';
export * from './vehicle-permissions.config';
export * from './notification-permissions.config';
export * from './subscriptional-payment-permissions.config';
export * from './glossary-permissions.config';
export * from './notification-template-permissions.config';
