/* eslint-disable import/no-cycle */
import { InvoiceEntity } from './invoice.entity';

export enum SubscriptionPaymentType {
  Advance = 'ADVANCE', // авансовый платёж (холдирование средств)
  AdvanceAccept = 'ADVANCE_ACCEPT', // списание авансового платежа
  AdvanceReverse = 'ADVANCE_REVERSE', // возврат авансового платежа
  Payment = 'PAYMENT', // оплата без холдирования средств
  Refund = 'REFUND', // возврат средств
}

export enum SubscriptionPaymentStatus {
  New = 'NEW',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Expired = 'EXPIRED',
}

export const SUBSCRIPTION_PAYMENT_STATUS_TRANSITIONS_MAP = new Map<
  SubscriptionPaymentStatus,
  SubscriptionPaymentStatus[]
>([
  [
    SubscriptionPaymentStatus.New,
    [SubscriptionPaymentStatus.Success, SubscriptionPaymentStatus.Failure, SubscriptionPaymentStatus.Expired],
  ],
  [SubscriptionPaymentStatus.Success, []],
  [SubscriptionPaymentStatus.Failure, [SubscriptionPaymentStatus.Success]],
  [SubscriptionPaymentStatus.Expired, []],
]);

export interface SubscriptionPaymentEntity {
  readonly id: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly invoiceId: string;
  externalPaymentId: string | null;
  url: string | null;
  successUrl: string | null;
  failedUrl: string | null;
  type: SubscriptionPaymentType;
  status: SubscriptionPaymentStatus;
  cardId: string | null;
  isAutoPayment: boolean;
  partnerId: string;
  invoice?: InvoiceEntity;
}

export type SubscriptionPaymentEntityRelationKey = keyof Pick<SubscriptionPaymentEntity, 'invoice'>;
export type SubscriptionPaymentEntityRelations = SubscriptionPaymentEntityRelationKey[] | 'all';
