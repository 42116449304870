import { EntityName } from '@store/backend-entity';

import { Models, OwnershipAction, UserRole } from '../../enums';

export const ownershipPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof OwnershipAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [
        OwnershipAction.read,
        OwnershipAction.create,
        OwnershipAction.update,
        OwnershipAction.finish,
        OwnershipAction.addDriver,
        OwnershipAction.deleteDriver,
      ],
      subject: Models.Ownership,
    },
    { actions: [OwnershipAction.read], subject: Models.Ownerships },
  ],

  [UserRole.Operator]: [
    {
      actions: [
        OwnershipAction.read,
        OwnershipAction.create,
        OwnershipAction.update,
        OwnershipAction.finish,
        OwnershipAction.addDriver,
        OwnershipAction.deleteDriver,
      ],
      subject: Models.Ownership,
    },
    { actions: [OwnershipAction.read], subject: Models.Ownerships },
  ],

  [UserRole.OperatorWithoutPBAccess]: [
    {
      actions: [
        OwnershipAction.read,
        OwnershipAction.create,
        OwnershipAction.update,
        OwnershipAction.finish,
        OwnershipAction.addDriver,
        OwnershipAction.deleteDriver,
      ],
      subject: Models.Ownership,
    },
    { actions: [OwnershipAction.read], subject: Models.Ownerships },
  ],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [
    { actions: [OwnershipAction.read], subject: Models.Ownership },
    { actions: [OwnershipAction.read], subject: Models.Ownerships },
  ],
};
