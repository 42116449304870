export enum EFacilityType {
  FinePayment = 'fine_payment',
  CkadPayment = 'ckad_payment',
  Telematics = 'telematics',
  Maintenance = 'maintenance',
  Vehicle = 'vehicle',
}

/** Тип услуги */
export type FacilityTypeEntity = {
  /** Тип */
  readonly type: EFacilityType;
  /** Название */
  name: string;
  /** Описание */
  description: string;
  /** Доступность */
  isAvailable: boolean;
  /** Массив партнёров, которым недоступен тип услуги */
  restrictedPartners: string[];
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
};
