export enum DeletePersonalDataTicketStatus {
  New = 'NEW',
  //...
}

export enum AddVehicleTicketStatus {
  New = 'NEW',
  InProgress = 'IN_PROGRESS',
  Returned = 'RETURNED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Reopen = 'REOPEN',
  Done = 'DONE',
  Closed = 'CLOSED',
}

export enum InstallDeviceTicketStatus {
  New = 'NEW',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  Reopen = 'REOPEN',
  Done = 'DONE',
}

export type TicketStatus = DeletePersonalDataTicketStatus | AddVehicleTicketStatus | InstallDeviceTicketStatus;
