export type DeviceTestEntity = {
  /** Идентификатор */
  readonly deviceId: string;
  /** Идентификатор (VIN) авто */
  vehicleVin: string;
  /** Серийный номер блока */
  serial: string;
  /** Протестирован ли блок */
  tested: boolean;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;
};
