import { I18n } from '@store/backend-common';

import { DeviceType } from '../control-center';

import { ConditionField } from './interfaces';

export enum TitleAlign {
  Auto = 'auto',
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Justify = 'justify',
}

export interface StoryConditions {
  partnerId?: ConditionField<string>;
  vehicleBrand?: ConditionField<string>;
  vehicleModel?: ConditionField<string>;
  vehicleDeviceType?: ConditionField<DeviceType>;
}

export type StoryEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Активность истории */
  active: boolean;
  /** Заголовок */
  title: I18n;
  /** Цвет заголовка hex */
  titleColor: string | null;
  /** Выравнивание заголовка */
  titleAlign: TitleAlign | null;
  /** Цвет фона hex */
  backgroundColor: string | null;
  /** Путь фонового изображения */
  backgroundImagePath: string | null;
  /** URL фонового изображения */
  backgroundImageUrl: string | null;
  /** HTML страницы */
  pages: string[];
  /** Позиция истории в списке */
  position: number;
  /** Условия доступности истории
   * [ {AND} OR {AND} OR ... ]
   */
  conditions: StoryConditions[];
  /** Словарь локализации */
  localization: Record<string, I18n>;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;

  /** Просмотрена ли история */
  viewed?: boolean;
};

export type StoryEntityRelations = [] | 'all';
