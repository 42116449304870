import { EntityName } from '@store/backend-entity';

import { DefaultAction, Models, UserRole } from '../../enums';

export const glossaryPermissions: Record<
  UserRole,
  { actions: Array<keyof typeof DefaultAction>; subject: Models; fields?: Array<EntityName> }[]
> = {
  [UserRole.Admin]: [
    {
      actions: [DefaultAction.read, DefaultAction.create, DefaultAction.update],
      subject: Models.Glossary,
    },
    {
      actions: [DefaultAction.read],
      subject: Models.Glossary,
    },
  ],

  [UserRole.Operator]: [],

  [UserRole.OperatorWithoutPBAccess]: [],

  [UserRole.DeviceOperator]: [],

  [UserRole.OperatorReadAccess]: [],
};
