import { ControlCenterInvoiceCreatedOutboxEntity } from './control-center-invoice-created-outbox.entity';
import { ControlCenterInvoiceDeletedOutboxEntity } from './control-center-invoice-deleted-outbox.entity';
import { ControlCenterInvoiceExpiredOutboxEntity } from './control-center-invoice-expired-outbox.entity';
import { ControlCenterInvoiceFailedOutboxEntity } from './control-center-invoice-failed-outbox.entity';
import { ControlCenterInvoicePaidOutboxEntity } from './control-center-invoice-paid-outbox.entity';
import { ControlCenterInvoiceUpdatedOutboxEntity } from './control-center-invoice-updated-outbox.entity';
import { ControlCenterInvoiceReversedOutboxEntity } from './control-center-invoice-reversed-outbox.entity';

export type ControlCenterInvoiceOutboxEntities =
  | ControlCenterInvoiceCreatedOutboxEntity
  | ControlCenterInvoiceDeletedOutboxEntity
  | ControlCenterInvoiceExpiredOutboxEntity
  | ControlCenterInvoiceFailedOutboxEntity
  | ControlCenterInvoicePaidOutboxEntity
  | ControlCenterInvoiceUpdatedOutboxEntity
  | ControlCenterInvoiceReversedOutboxEntity;

export * from './control-center-invoice-outbox-event-type.enum';
export * from './control-center-invoice-created-outbox.entity';
export * from './control-center-invoice-deleted-outbox.entity';
export * from './control-center-invoice-expired-outbox.entity';
export * from './control-center-invoice-failed-outbox.entity';
export * from './control-center-invoice-paid-outbox.entity';
export * from './control-center-invoice-updated-outbox.entity';
export * from './control-center-invoice-reversed-outbox.entity';
