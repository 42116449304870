export type DeviceTestEntity = {
  /** Идентификатор */
  deviceId: string | null;

  /** Идентификатор (VIN) авто */
  readonly vehicleVin: string;

  /** Серийный номер блока */
  serial: string | null;

  /** Протестирован ли блок */
  tested: boolean;

  /** Код комплектации */
  equipmentCode: string | null;

  /** Установлена ли настройка комплектации */
  settingsSet: boolean;

  /** Дата создания */
  readonly createdAt: Date;

  /** Дата обновления */
  updatedAt: Date;
};
