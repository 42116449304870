export enum ServiceName {
  ControlCenter = 'control_center',
  DeviceTest = 'device-test',
  Users = 'users',
  Payment = 'payment',
  Fines = 'fines',
  Facilities = 'facilities',
  Tickets = 'tickets',
  Contents = 'contents',
  Dealers = 'dealers',
  Widgets = 'widgets',
  Catalog = 'catalog',
  Dashboard = 'dashboard',
}
