import { DeviceType, EDeviceModel } from '../control-center';
import { ExtraParameters, PaymentSubjectTypeEnum } from '../payment';
import { EFacilityType, FacilityTypeEntity } from './facility-type.entity';
import { ProviderEntity } from './provider.entity';

export enum FacilityCode {
  Fines = 'FINES',
  Ckad = 'CKAD',
  Monitoring = 'MONITORING',
  Statistics = 'STATISTICS',
  Drivers = 'DRIVERS',
  Dealer = 'DEALER',
  Maintenance = 'MAINTENANCE',
  Subscription = 'SUBSCRIPTION',
  VehicleInfo = 'VEHICLE_INFO',
}

export interface FacilityFilters {
  //TODO: Уточнить
  regions?: string[];
  deviceTypes?: DeviceType[];
  deviceModels?: EDeviceModel[];
  vehicleModels?: string[];
  vehicleBrands?: string[];
  //TODO: Тип
  vehicleEngineTypes?: string[];
}

/** Услуга */
export type FacilityEntity = {
  /** Идентификатор */
  readonly id: string;
  /** Идентификатор поставщика */
  readonly providerId: string;
  /** Идентификатор типа */
  readonly type: EFacilityType;
  /** Код услуги */
  readonly code: FacilityCode;
  /** Доступность услуги */
  isAvailable: boolean;
  /** Фильтры */
  filters: FacilityFilters | null;
  /** Размер комиссии (копейки) */
  feeAmount: number;
  /** Размер комиссии (проценты) */
  feePercent: number;
  /** Услуга является бесплатной */
  isFree: boolean;
  /** Необходимо ли холдирование средств при оплате */
  isFreeze: boolean;
  /** Требуются фискальные данные */
  isFiscalNeeded: boolean;
  /** Требуется наличие подписки */
  subscriptionRequired: boolean;
  /**  */
  paymentSubjectType: PaymentSubjectTypeEnum;
  /** Экстра параметры */
  extraParameters: ExtraParameters | null;

  /** требуются экстра параметры */
  isExtraParametersNeeded: boolean;
  /** Дата создания */
  readonly createdAt: Date;
  /** Дата обновления */
  updatedAt: Date;

  /** Сущность типа услуги */
  facilityType?: FacilityTypeEntity;
  /** Сущность поставщика услуги */
  provider?: ProviderEntity;
};

export type FacilityEntityRelationKey = keyof Pick<FacilityEntity, 'facilityType' | 'provider'>;
export type FacilityEntityRelations = FacilityEntityRelationKey[] | 'all';
