import { DeviceTestLastPacket } from './device-test-last-packet.interface';
import { DeviceTestTelematicChangesInterface } from './device-test-telematic-changes.interface';

export interface DeviceTestStatusInterface {
  lastPacket: DeviceTestLastPacket | null;
  telematicChanges: DeviceTestTelematicChangesInterface | null;
  serial: string;
  deviceId: string;
  vin: string;
  firmwareVersion: number | null;
}
