import { I18n } from '@store/backend-common';

import { DeviceType } from '../../control-center';

export interface WidgetDataSubscription {
  isActive: boolean;
  deviceType?: DeviceType;
  dueDate?: Date | null;
  daysLeft?: number | null;
  isToday: boolean;
  descriptionImportant: I18n | null;
}
